import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Account, PaymentMethod, SubscriptionStatus } from "@common/ADAPT.Common.Model/account/account";
import { Currency } from "@common/ADAPT.Common.Model/embed/currency";
import { PricingModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ICardPartialDetails } from "@common/payment-processing/card-partial-details.interface";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import { catchError, from, lastValueFrom, Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { StakeholderServicesService } from "../../tools/stakeholder-services.service";
import { OrganisationsService } from "../organisations.service";

enum SubscriptionStatusTransition {
    ExtendTrial = "Extend trial",
    ArchiveOrganisation = "Archive organisation",
}

@Component({
    selector: "adapt-account-details",
    templateUrl: "./account-details.component.html",
})
export class AccountDetailsComponent implements OnChanges {
    @Input() public organisation?: Organisation;

    public readonly ValidStatusTransitions: Record<SubscriptionStatus, SubscriptionStatusTransition[]> = {
        [SubscriptionStatus.Active]: [
            SubscriptionStatusTransition.ArchiveOrganisation,
        ],
        [SubscriptionStatus.Trial]: [
            SubscriptionStatusTransition.ArchiveOrganisation,
            SubscriptionStatusTransition.ExtendTrial,
        ],
        [SubscriptionStatus.PendingInactive]: [
            SubscriptionStatusTransition.ArchiveOrganisation,
        ],
        [SubscriptionStatus.Inactive]: [
            SubscriptionStatusTransition.ExtendTrial,
        ],
    };

    public billingPeriods: { id: number, name: string }[] = [
        {
            id: 1,
            name: "Monthly",
        },
        {
            id: 12,
            name: "Annually",
        },
    ];

    public pricingModelTypes: string[] = [
        PricingModel.FixedPricingModelDescription,
        PricingModel.PerUserPricingModelDescription,
    ];

    public paymentMethods: { id: PaymentMethod, name: string }[] = [
        {
            id: PaymentMethod.CreditCard,
            name: "Credit card",
        },
        {
            id: PaymentMethod.BilledExternally,
            name: "Billed externally",
        },
    ];

    public currencies$: Observable<Currency[]>;
    public pricingModels: PricingModel[] = [];

    public account?: Account;

    public editBillingDetailsMode = false;
    public editPaymentMode = false;
    public billingCommencing = false;

    public pricingModelType = PricingModel.PerUserPricingModelDescription;

    public errorMessage?: string;

    public cardDetails?: ICardPartialDetails;
    public cardDetailsLoading = true;

    public statusTransition?: SubscriptionStatusTransition;

    public constructor(
        private organisationsService: OrganisationsService,
        private paymentProcessingService: PaymentProcessingService,
        private dialogService: AdaptCommonDialogService,
        private stakeholderServicesService: StakeholderServicesService,
    ) {
        this.currencies$ = this.organisationsService.getCurrencies();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation) {
            this.updateOrganisationData();
        }
    }

    public get isAlto() {
        return !AdaptClientConfiguration.IsCurrentEmbedApiBaseUri;
    }

    public get isBilledExternally() {
        return this.account?.paymentMethod === PaymentMethod.BilledExternally;
    }

    public onStatusTransitionSelected(transition: SubscriptionStatusTransition) {
        this.statusTransition = transition;
    }

    private cancelAnyEdit() {
        if (this.editBillingDetailsMode) {
            this.cancelBillingDetails();
        }

        if (this.editPaymentMode) {
            this.cancelPayment();
        }
    }

    public editBillingDetails() {
        this.cancelAnyEdit();
        this.editBillingDetailsMode = true;
    }

    @Autobind
    public saveBillingDetails() {
        return this.organisationsService.save().pipe(
            tap(() => {
                this.stopEditingBillingDetails();
                this.updateOrganisationData();
            }),
            catchError((e: any) => this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }

    @Autobind
    public cancelBillingDetails() {
        return this.organisationsService.cancel().pipe(
            tap(() => this.stopEditingBillingDetails()),
        );
    }

    public onPricingModelChanged() {
        if (this.account && !this.account.pricingModel?.monthlyFeeDollars) {
            // discount is from pricing model - if no pricing model or per user pricing model, there won't be any discount and billing period
            // should be reset
            this.account.billingPeriod = 1;
        }
    }

    public onPaymentMethodChanged() {
        if (this.account?.paymentMethod === PaymentMethod.BilledExternally) {
            this.account.monthlyFeeDollars = 0;
            this.account.pricingModelId = null;
            this.account.billingPeriod = 1;
        }
    }

    private stopEditingBillingDetails() {
        this.errorMessage = undefined;
        this.editBillingDetailsMode = false;
    }

    public editPayment() {
        this.cancelAnyEdit();
        this.editPaymentMode = true;
        this.statusTransition = undefined;
    }

    @Autobind
    public savePayment() {
        return this.savePaymentInternal().pipe(
            catchError((e: any) => this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }

    private savePaymentInternal() {
        return this.organisationsService.save().pipe(
            switchMap(() => this.applySubscriptionStatusTransition()),
            tap(() => {
                this.stopEditingPayment();
                this.updateOrganisationData();
                this.statusTransition = undefined;
            }),
        );
    }

    private applySubscriptionStatusTransition() {
        let action: Observable<any> | undefined;
        switch (this.statusTransition) {
            case SubscriptionStatusTransition.ExtendTrial:
                action = from(this.stakeholderServicesService.resumeTrial({ organisationId: this.account!.organisationId }));
                break;
            case SubscriptionStatusTransition.ArchiveOrganisation:
                action = from(this.stakeholderServicesService.archiveOrganisation({ organisationId: this.account!.organisationId }));
                break;
            default:
                action = undefined;
                break;
        }

        return action
            ? action.pipe(
                switchMap(() => this.organisationsService.getAccountsForOrganisation(this.account!.organisationId, true)),
            ) : of(undefined);
    }

    @Autobind
    public cancelPayment() {
        this.statusTransition = undefined;
        return this.organisationsService.cancel().pipe(
            tap(() => this.stopEditingPayment()),
        );
    }

    public stopEditingPayment() {
        this.errorMessage = undefined;
        this.editPaymentMode = false;
    }

    @Autobind
    public removeDefaultCard() {
        const dialogData = {
            title: "Remove default card",
            message: `Are you sure you would like to remove the default card for <b>${this.organisation!.name}</b>? This cannot be easily undone!`,
        };

        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(() => this.stakeholderServicesService.detachPaymentMethod({ organisationId: this.organisation!.organisationId })),
            switchMap(() => this.dialogService.showMessageDialog("Remove default card", "The default card has been removed for this organisation.")),
            switchMap(() => this.updateOrganisationData()),
        );
    }

    @Autobind
    private async updateOrganisationData() {
        if (!this.organisation) {
            return;
        }

        // fetch the pricing models prior to anything else
        // (theres a possible race condition where the pricingModelType is set, but pricing models isn't, so the onChangedEvent handler falls over)
        // todo: move this to component init to remove this hack
        this.pricingModels = await lastValueFrom(this.organisationsService.getPricingModels());

        this.account = this.organisation.account;
        this.pricingModelType = this.account!.pricingModelId
            ? PricingModel.PerUserPricingModelDescription
            : PricingModel.FixedPricingModelDescription;

        await this.paymentProcessingService.getInvoices(this.organisation.organisationId);

        if (this.account?.extensions.isBilledUsingCreditCard && this.account?.extensions.isCreditCardSet) {
            this.cardDetailsLoading = true;
            this.cardDetails = await lastValueFrom(this.paymentProcessingService.getCreditCardPartialDetails({ organisationId: this.organisation.organisationId }));
            this.cardDetailsLoading = false;
        }
    }

    public pricingModelTypeChanged(event: IDxSelectBoxValueChangedEvent<string>) {
        if (event.value === PricingModel.FixedPricingModelDescription) {
            this.account!.pricingModelId = null;
        } else if (event.value === PricingModel.PerUserPricingModelDescription) {
            const defaultPricingModel = this.pricingModels.find((i) => i.isDefault);
            if (!defaultPricingModel) {
                throw new Error("missing default pricing model");
            }

            this.account!.pricingModelId = defaultPricingModel.pricingModelId;
            this.account!.monthlyFeeDollars = 0;
            this.account!.billingPeriod = 1;
            this.account!.paymentMethod = PaymentMethod.CreditCard;
        } else {
            throw new Error("unknown pricing model type");
        }
    }
}
