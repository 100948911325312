import { Component, Injector, OnInit } from "@angular/core";
import { SubscriptionStatus } from "@common/ADAPT.Common.Model/account/account";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { NimbusDataService } from "app/services/nimbus-data.service";
import { lastValueFrom } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-archive-organisation-page",
    templateUrl: "./archive-organisation-page.component.html",
})
export class ArchiveOrganisationPageComponent extends BaseRoutedComponent implements OnInit {
    public organisation?: Organisation;
    public error?: string;

    public constructor(
        private commonDialogService: AdaptCommonDialogService,
        private stakeholderServicesService: StakeholderServicesService,
        private nimbusDataService: NimbusDataService,
        injector: Injector,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        // prime organisation accounts so we can filter out already archived organisations
        await lastValueFrom(this.nimbusDataService.getAccounts());
        this.notifyActivated();
    }

    @Autobind
    public archive() {
        if (!this.organisation) {
            return this.commonDialogService.showMessageDialog("Error", "Please fill in all fields");
        }

        const dialogData: IConfirmationDialogData = {
            title: "Archive Organisation",
            message: "Are you sure you want to archive this organisation?",
            confirmButtonText: "Archive",
        };

        return this.commonDialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
            filter((dialogResult) => !!dialogResult),
            switchMap(() => this.stakeholderServicesService.archiveOrganisation({ organisationId: this.organisation!.organisationId })),
            switchMap(() => this.commonDialogService.showMessageDialog("Success!", "Organisation Archived!")),
            catchError((e) => this.error = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }

    public orgFilter(organisation: Organisation) {
        return organisation.account.status !== SubscriptionStatus.Inactive;
    }
}
